/**
 * Created by Ivan on 17.09.2023
 */

var ClearPiecesButtonBooster = BaseButtonBooster.extend({
    ctor: function (booster) {
        this._super({
            booster: booster,
            styles: cleverapps.styles.ClearPiecesButtonBooster
        });

        this.setLocalZOrder(cleverapps.styles.ClearPiecesButtonBooster.zOrder);
    },

    createIcon: function () {
        return new cc.Sprite(bundles.buttons.frames.clear_pieces_png);
    }
});

cleverapps.styles.ClearPiecesButtonBooster = {
    size: [
        { width: 180, height: 130 },
        { width: 220, height: 100 },
        { width: 220, height: 100 }
    ],
    zOrder: 0,

    position: [
        { x: { align: "center", dx: 200 }, y: { align: "bottom", dy: 45 } },
        { x: { align: "right", dx: -30 }, y: { align: "center", dy: -150 } },
        { x: { align: "right", dx: -30 }, y: { align: "center", dy: -150 } }
    ],

    phone: {
        scale: 0.8
    },

    content: {
        layout: [{
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 33 }
        }, {
            x: { align: "center", dx: 30 },
            y: { align: "center", dy: 7 }
        }, {
            x: { align: "center", dx: 30 },
            y: { align: "center", dy: 7 }
        }],
        margin: [5, 8, 8],
        direction: [cleverapps.UI.VERTICAL, cleverapps.UI.HORIZONTAL, cleverapps.UI.HORIZONTAL],
        reversed: [false, true, true],
        freeBlock: {
            textWidth: 120
        }
    }
};