/**
 * Created by Ivan on 15.09.2023
 */

var ReplaceAllButtonBooster = BaseButtonBooster.extend({
    ctor: function (booster) {
        this._super({
            booster: booster,
            styles: cleverapps.styles.ReplaceAllButtonBooster
        });

        this.setLocalZOrder(cleverapps.styles.ReplaceAllButtonBooster.zOrder);
    },

    createIcon: function () {
        var icon = new cleverapps.Spine(bundles.buttons.jsons.replace_all_json);
        icon.setAnimation(0, "idle", false);
        return icon;
    },

    onExecute: function () {
        this.icon.setAnimation(0, "animation", false);
        cleverapps.audio.playSound(this.booster.executeEffect);
    }
});

cleverapps.styles.ReplaceAllButtonBooster = {
    size: [
        { width: 180, height: 130 },
        { width: 220, height: 100 },
        { width: 220, height: 100 }
    ],
    zOrder: 0,

    position: [
        { x: { align: "center", dx: 0 }, y: { align: "bottom", dy: 45 } },
        { x: { align: "right", dx: -30 }, y: { align: "center", dy: 0 } },
        { x: { align: "right", dx: -30 }, y: { align: "center", dy: 0 } }
    ],

    phone: {
        scale: 0.8
    },

    content: {
        layout: [{
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 33 }
        }, {
            x: { align: "center", dx: 20 },
            y: { align: "center", dy: 7 }
        }, {
            x: { align: "center", dx: 20 },
            y: { align: "center", dy: 7 }
        }],
        margin: [5, 8, 8],
        direction: [cleverapps.UI.VERTICAL, cleverapps.UI.HORIZONTAL, cleverapps.UI.HORIZONTAL],
        reversed: [false, true, true],
        freeBlock: {
            textWidth: 120
        }
    }
};