/**
 * Created by Aleksandr on 27.03.2023
 */

cleverapps.override(cleverapps.Tool.game, {
    exclamations: ToolFolder.create(function () {
        var res = {};

        var showExclamations = function (text) {
            cleverapps.exclamation.show(text);
            cleverapps.audio.playSound(bundles.game.urls.message_effect);
        };

        res["2 lines"] = function () {
            showExclamations("message.good");
        };
        res["3 lines"] = function () {
            showExclamations("message.great");
        };
        res["4+ lines"] = function () {
            showExclamations("message.excellent");
        };
        res.clearAll = function () {
            showExclamations("message.super");
        };
        return res;
    }),

    setScore: function () {
        var callback = function (userScore) {
            Game.currentGame.score.setPoints(parseInt(userScore || "0"));
        };

        displayToolPrompt(callback, "Enter score", 0);
    },

    setHighscore: function () {
        var callback = function (userScore) {
            var points = parseInt(userScore || "0");
            cleverapps.highscore.setScore(points);
            cleverapps.highscore.save();

            var highScoreView = $(HighScoreView).get();
            if (highScoreView) {
                highScoreView.threshold = points;
                highScoreView.scoreView.setString(points);
            } else if (points) {
                var scene = cleverapps.scenes.getRunningScene();
                scene.createHighScoreView();
                scene.updateSize();
                scene.setupChildren();
                $(HighScoreView).get().showScore();
            }
        };

        displayToolPrompt(callback, "Enter highscore", 0);
    },

    noMoves: function () {
        Game.currentGame.noMoves();
    },

    noMovesMemorable: function () {
        Game.currentGame.score.points = Math.max(Game.currentGame.score.points, 200);
        Game.currentGame.noMoves();
    },

    clearAll: function () {
        var game = Game.currentGame;
        game.grid.clear();
    },

    setMoves20: function () {
        var game = Game.currentGame;
        if (game.moves < 20) {
            game.moves = 20;
        }
    },

    "rows and columns": function () {
        var game = Game.currentGame;
        var data = {
            piece: [
                "xxx",
                "xxx"
            ]
        };

        game.customGridForPiece(data.piece);
        game.pieces.onHidePieces();
        game.pieces.getNewPieces(function () {});
    },

    "row": function () {
        var game = Game.currentGame;
        var data = {
            piece: [
                "x",
                "x"
            ]
        };

        game.customGridForPiece(data.piece);
        game.pieces.onHidePieces();
        game.pieces.getNewPieces(function () {});
    },

    "column": function () {
        var game = Game.currentGame;
        var data = {
            piece: [
                "xxx"
            ]
        };

        game.customGridForPiece(data.piece);
        game.pieces.onHidePieces();
        game.pieces.getNewPieces(function () {});
    },
    
    alwaysMaxExplode: function () {
        BlocksGame.MODES[0].steps = 1;
        BlocksGame.MODES[1].maxExplode = "100%";
        BlocksGame.MODES[2].maxExplode = "100%";
        BlocksGame.MODES[3].maxExplode = "100%";
    },

    setMode: function () {
        var game = Game.currentGame;
        var callback = function (mode) {
            var maxMode = BlocksGame.MODES.length - 1;
            if (mode > maxMode) {
                mode = maxMode;
            } else if (mode < 0) {
                mode = 0;
            }

            game._modeCounter = 0;
            game.mode = +mode;
        };

        displayToolPrompt(callback, "Set mode", "0-4");
    }
});