/**
 * Created by olga on 04.03.2024
 */

var BlocksEditorView = EditorViewBase.extend({
    ctor: function (editor) {
        if (resolutionScale < 1) {
            throw "resolutionScale must be 1 in editor!";
        }

        this._super(editor);

        var styles = cleverapps.styles.BlocksEditorView;

        var gridView = this.gridView = new GridView(Game.currentGame.grid);
        gridView.setPositionRound(styles.grid);
        this.addChild(gridView);

        var goalsView = new EditorGoalsView(editor.goals);
        this.addChild(goalsView);
        goalsView.setPositionRound(styles.goals);

        var gitButtons = new GitButtonsView();
        this.addChild(gitButtons);
        gitButtons.setPositionRound(styles.gitButtons);

        var modeSelectorView = new ModeSelectorView(editor.modeSelector);
        this.addChild(modeSelectorView);
        modeSelectorView.setPositionRound(styles.modeSelector);
    }

});

EditorView = BlocksEditorView;

cleverapps.styles.BlocksEditorView = {
    gitButtons: {
        x: { align: "center", dx: 300 },
        y: { align: "top", dy: -20 }
    },

    grid: {
        x: { align: "center" },
        y: { align: "center", dy: 100 }
    },

    goals: {
        x: { align: "center", dx: 300 },
        y: { align: "bottom", dy: 15 }
    },

    points: {
        x: { align: "center", dx: 600 },
        y: { align: "bottom", dy: 215 }
    },

    modeSelector: {
        x: { align: "center", dx: -300 },
        y: { align: "bottom", dy: 100 }
    }

};
