/**
 * Created by olga on 05.03.2024
 */

var EditorGoal = function (type) {
    cleverapps.EventEmitter.call(this);
    this.type = type;
    this.target = 0;
};

EditorGoal.prototype = Object.create(cleverapps.EventEmitter.prototype);
EditorGoal.prototype.constructor = EditorGoal;

EditorGoal.prototype.setTarget = function (target) {
    this.target = target;
    Editor.currentEditor.save();
};

EditorGoal.prototype.update = function (target) {
    this.setTarget(target);
    this.trigger("update", target);
};

EditorGoal.prototype.getInfo = function () {
    if (this.target !== 0) {
        return {
            type: this.type,
            target: this.target
        };
    }
};
