/**
 * Created by olga on 04.03.2024
 */

var EditorGoals = function () {
    cleverapps.EventEmitter.call(this);
    this.goals = {};
    Object.values(BlocksGame.GOALS_TYPES).forEach(function (type) {
        this.goals[type] = new EditorGoal(type);
    }.bind(this));
};

EditorGoals.prototype = Object.create(cleverapps.EventEmitter.prototype);
EditorGoals.prototype.constructor = EditorGoals;

EditorGoals.prototype.getItems = function () {
    return Object.values(this.goals);
};

EditorGoals.prototype.update = function (saveGoals) {
    saveGoals.forEach(function (goal) {
        this.goals[goal.type].update(goal.target);
    }.bind(this));
};

EditorGoals.prototype.getInfo = function () {
    var goals = [];
    this.getItems().forEach(function (goal) {
        var info = goal.getInfo();
        if (info) {
            goals.push(info);
        }
    });

    return goals;
};
