/**
 * Created by Andrey Popov on 4/7/23.
 */

var Forms = {
    ToKey: function (form) {
        return form.join("-");
    },

    GetHardness: function (form) {
        return Forms._HARDNESS_BY_KEY[Forms.ToKey(form)];
    },

    Mirror: function (form) {
        form = cleverapps.clone(form);
        for (var i = 0; i < form.length; i++) {
            form[i] = form[i].split("").reverse().join("");
        }

        return form;
    },

    Rotate90: function (form) {
        var res = [];

        for (var i = 0; i < form[0].length; i++) {
            var s = [];
            for (var j = 0; j < form.length; j++) {
                s.push(form[form.length - 1 - j][i]);
            }
            res.push(s.join(""));
        }

        return res;
    },

    Square: function (form) {
        var square = 0;

        for (var i = 0; i < form.length; i++) {
            for (var j = 0; j < form[0].length; j++) {
                if (form[i][j] === "x") {
                    square++;
                }
            }
        }

        return square;
    },

    convertFormToCoordinates: function (form) {
        var height = form.length;
        var width = form[0].length;
        var coordinates = [];
        for (var i = 0; i < height; i++) {
            for (var j = 0; j < width; j++) {
                if (form[i][j] === "x") {
                    coordinates.push({
                        x: j,
                        y: i
                    });
                }
            }
        }
        return coordinates;
    }
};

Forms.__EASY = [
    [
        "x"
    ],
    [
        "xx"
    ],
    [
        "xxx"
    ],
    [
        "xxxx"
    ],
    [
        "xx",
        "xx"
    ],
    [
        "xx",
        ".x"
    ]
];

Forms.__NORMAL = [
    [
        "xxx",
        "xxx"
    ],
    [
        "xxx",
        ".x."
    ],
    [
        ".x",
        ".x",
        "xx"
    ],
    [
        "xx.",
        ".xx"
    ],
    [
        "xxxxx"
    ]
];

Forms.__HARD = [
    [
        "xxx",
        "xxx",
        "xxx"
    ],

    [
        "xxx",
        "..x",
        "..x"
    ]
];

Forms.__NIGHTMARE = [
    [
        ".x.",
        "xxx",
        ".x."
    ],

    [
        "xxx",
        ".x.",
        ".x."
    ],

    [
        "x.x",
        "xxx"
    ]
];

Forms.__BY_HARDNESS = [
    {
        forms: Forms.__EASY,
        points: 0
    }, {
        forms: Forms.__NORMAL,
        points: 3
    }, {
        forms: Forms.__HARD,
        points: 7
    }, {
        forms: Forms.__NIGHTMARE,
        points: 9
    }
];

Forms._HARDNESS_BY_KEY = {};
Forms.ALL_POSSIBLE_VARIANTS = [];

Forms.ALL_POSSIBLE_VARIANTS = [];

for (var i = 0; i < Forms.__BY_HARDNESS.length; i++) {
    var forms = Forms.__BY_HARDNESS[i].forms;
    var points = Forms.__BY_HARDNESS[i].points;

    var modifiedForms = forms.map(function (form) {
        var modified = [];

        var plain = form;
        var mirror = Forms.Mirror(form);

        for (var i = 0; i < 4; i++) {
            modified.push(plain);
            modified.push(mirror);

            if (i !== 3) {
                plain = Forms.Rotate90(plain);
                mirror = Forms.Rotate90(mirror);
            }
        }

        return cleverapps.unique(modified, Forms.ToKey);
    }).flat();

    modifiedForms.forEach(function (form) {
        Forms._HARDNESS_BY_KEY[Forms.ToKey(form)] = points;
    });

    Forms.ALL_POSSIBLE_VARIANTS = Forms.ALL_POSSIBLE_VARIANTS.concat(modifiedForms);
}

Forms.ALL_POSSIBLE_VARIANTS.sort(function (a, b) {
    return Forms.Square(b) - Forms.Square(a);
});
