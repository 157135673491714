/**
 * Created by olga on 04.03.2024
 */

var BlocksEditor = function () {
    EditorBase.call(this);
    this.orangery = new Orangery(BlocksOrangery);
    this.goals = new EditorGoals();
    this.modeSelector = new ModeSelector();
};

BlocksEditor.prototype = Object.create(EditorBase.prototype);
BlocksEditor.prototype.constructor = BlocksEditor;

BlocksEditor.BUNDLES = ["admin"];

Editor = BlocksEditor;

BlocksEditor.prototype._load = function (levelContent) {
    if (levelContent.goals && levelContent.goals.length > BlocksEditor.MAX_GOALS) {
        throw "Level contain too many goals!";
    }

    if (levelContent.goals) {
        this.goals.update(levelContent.goals);
    }

    this.modeSelector.update(levelContent);
};

BlocksEditor.prototype.getSaveData = function () {
    var data = EditorBase.prototype.getSaveData.call(this);
    data.cells = Game.currentGame.grid.getInfo();
    data.goals = this.goals.getInfo();

    Object.assign(data, this.modeSelector.getInfo());

    return data;
};

BlocksEditor.prototype.restoreCell = function (cell) {
    var prevProperties = cell.getInfo();
    return function () {
        cell.setProperties(prevProperties);
        this.save();
    }.bind(this);
};

BlocksEditor.MAX_GOALS = 3;