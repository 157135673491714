/**
 * Created by Andrey Popov on 22.03.2023
 */

var Grid = function (content, savedCells) {
    cleverapps.EventEmitter.call(this);

    var loadedCells = savedCells || content.cells;

    this.cells = [];
    for (var h = 0; h < Grid.HEIGHT; h++) {
        this.cells.push([]);
        for (var w = 0; w < Grid.WIDTH; w++) {
            this.cells[h].push(new Cell({ x: w, y: h }));
        }
    }
    if (loadedCells) {
        this.addRegularMarks(loadedCells);
        loadedCells.forEach(function (cell) {
            this.setCellProperties(cell.x, cell.y, cell);
        }.bind(this));
    }

    this.getView = function () {};

    this.updatePiecesData = function () {};
};

Grid.prototype = Object.create(cleverapps.EventEmitter.prototype);
Grid.prototype.constructor = Grid;

if (cleverapps.config.debugMode) {
    Grid.prototype.onClick = function (x, y) {
        var cell = this.getCell(x, y);
        var orangery = cleverapps.config.editorMode ? Editor.currentEditor.orangery : Game.currentGame.orangery;

        if (orangery && orangery.selected) {
            if (orangery.selected.form) {
                var piece = new Piece(undefined, {
                    form: orangery.selected.form,
                    color: orangery.selected.color,
                    mark: orangery.selected.mark,
                    components: orangery.selected.components,
                    fromOrangery: true
                });
                Game.currentGame.placePiece(piece, x, y);
                return;
            }

            if (orangery.selected.eraser) {
                if (!cell.isEmpty()) {
                    cell.resetProperties();
                }
                return;
            }

            if (orangery.selected.color !== undefined) {
                if (!cell.isEmpty()) {
                    cell.setProperties({ color: orangery.selected.color });
                }
                return;
            }
        }

        if (!cell.isEmpty()) {
            cell.explode(cell.color);
        }
    };
}

Grid.prototype.addRegularMarks = function (cells) {
    if (cleverapps.config.editorMode || cleverapps.config.adminMode) {
        return;
    }

    if (Game.currentGame.getMissionType() === Mission.TYPE_LETTER) {
        var availableCells = cells.filter(function (cell) {
            return !cell.mark;
        });

        var letterMarksCount = Math.min(Math.floor(1 + 0.3 * availableCells.length), availableCells.length);
        cleverapps.Random.chooseAmount(availableCells, letterMarksCount).forEach(function (cell) {
            cell.mark = "letter";
        });
    }
};

Grid.prototype.clear = function (silent) {
    this.iterateCells(function (cell) {
        cell.resetProperties(silent);
    });
};

Grid.prototype.hideGrid = function (callback) {
    this.trigger("hideGrid", callback);
};

Grid.prototype.changeModeFrameColor = function (color) {
    this.trigger("changeModeFrameColor", color);
};

Grid.prototype.placeFormErrors = function (form, x, y) {
    if (x + form[0].length > Grid.WIDTH || y + form.length > Grid.HEIGHT) {
        return Infinity;
    }

    var errors = 0;

    Forms.convertFormToCoordinates(form).forEach(function (c) {
        var cell = this.getCell(x + c.x, y + c.y);
        if (!cell.isEmpty()) {
            errors++;
        }
    }, this);

    return errors;
};

Grid.prototype.listFilledLines = function () {
    var rows = [];
    var i, j;
    var cell;
    for (i = 0; i < Grid.HEIGHT; i++) {
        var isRow = true;
        for (j = 0; j < Grid.WIDTH; j++) {
            cell = this.getCell(j, i);
            if (cell.isEmpty() && !cell.getHover()) {
                isRow = false;
                break;
            }
        }

        if (isRow) {
            rows.push(i);
        }
    }

    var columns = [];
    for (j = 0; j < Grid.WIDTH; j++) {
        var isColumn = true;
        for (i = 0; i < Grid.HEIGHT; i++) {
            cell = this.getCell(j, i);
            if (cell.isEmpty() && !cell.getHover()) {
                isColumn = false;
                break;
            }
        }

        if (isColumn) {
            columns.push(j);
        }
    }

    return {
        rows: rows,
        columns: columns
    };
};

Grid.prototype.isInside = function (x, y) {
    return x >= 0 && y >= 0 && x < Grid.WIDTH && y < Grid.HEIGHT;
};

Grid.prototype.isEmpty = function () {
    var isEmpty = true;
    this.iterateCells(function (cell) {
        if (!cell.isEmpty()) {
            isEmpty = false;
        }
    });
    return isEmpty;
};

Grid.prototype.getCell = function (x, y) {
    return this.cells[y] && this.cells[y][x];
};

Grid.prototype.setCellProperties = function (x, y, options) {
    this.cells[y][x].setProperties(options);
};

Grid.prototype.iterateCells = function (iterator) {
    for (var i = 0; i < Grid.HEIGHT; i++) {
        for (var j = 0; j < Grid.WIDTH; j++) {
            var cell = this.cells[i][j];
            iterator(cell, j, i);
        }
    }
};

Grid.prototype.iterateByRowsOrColumns = function (rows, columns, iterator) {
    rows.forEach(function (row) {
        for (var j = 0; j < Grid.WIDTH; j++) {
            var cell = this.cells[row][j];
            iterator(cell);
        }
    }, this);

    columns.forEach(function (column) {
        for (var i = 0; i < Grid.HEIGHT; i++) {
            var cell = this.cells[i][column];
            iterator(cell);
        }
    }, this);
};

Grid.prototype.setExplodeHint = function (rows, columns, color) {
    this.createExplodeHint(rows, columns, color);
    this.iterateByRowsOrColumns(rows, columns, function (cell) {
        cell.updateViewProperties({ color: color, mark: cell.getMark() });
    });
};

Grid.prototype.resetExplodeHint = function (rows, columns) {
    this.iterateByRowsOrColumns(rows, columns, function (cell) {
        cell.updateViewProperties();
    });
    this.trigger("resetExplodeHint");
};

Grid.prototype.explodeRowsOrColumns = function (rows, columns, color) {
    this.iterateByRowsOrColumns(rows, columns, function (cell) {
        cell.explode();
    });

    this.trigger("explode", rows, columns, color);
    Game.currentGame.goalCounter.setTimeout(function () {
    }, Grid.EXPLODE_DURATION);
};

Grid.prototype.showCombo = function (points) {
    this.trigger("showCombo", points);
};

Grid.prototype.showHint = function (form, hint) {
    this.trigger("showHint", form, hint);
};

Grid.prototype.hideHint = function (form, hint) {
    this.trigger("hideHint", form, hint);
};

Grid.prototype.createExplodeHint = function (rows, columns, color) {
    if (cleverapps.gameModes.hideExplodeHint) {
        return;
    }

    var hints = [];

    var hint;
    var last;

    if (rows.length > 0) {
        last = rows[0];
        hint = {
            height: 1,
            x: Grid.WIDTH / 2,
            y: last
        };
        for (var i = 1; i < rows.length; i++) {
            if (last + 1 === rows[i]) {
                hint.height += 1;
            } else {
                hints.push(hint);
                hint = {
                    height: 1,
                    x: Grid.WIDTH / 2,
                    y: rows[i]
                };
            }
            last = rows[i];
        }
        hints.push(hint);
    }

    if (columns.length > 0) {
        last = columns[0];
        hint = {
            width: 1,
            x: last,
            y: Grid.HEIGHT / 2,
            isVertical: true
        };
        for (var j = 1; j < columns.length; j++) {
            if (last + 1 === columns[j]) {
                hint.width += 1;
            } else {
                hints.push(hint);
                hint = {
                    width: 1,
                    x: columns[j],
                    y: Grid.HEIGHT / 2,
                    isVertical: true
                };
            }
            last = columns[j];
        }
        hints.push(hint);
    }

    this.trigger("explodeHint", hints, color);
};

Grid.prototype.getInfo = function () {
    var cells = [];
    this.iterateCells(function (cell) {
        var info = cell.getInfo();
        if (info) {
            cells.push(info);
        }
    });
    return cells;
};

Grid.prototype.getCellsWithGoal = function (goalType) {
    var cells = [];
    this.iterateCells(function (cell) {
        var mark = cell.getMark();
        if (mark && mark.getTargetName() === goalType) {
            cells.push(cell);
        }
    });
    return cells;
};

Grid.prototype.fill = function (fillEffect, callback, options) {
    options = options || {};
    var orderedCells = fillEffect(this.cells);
    orderedCells = fillEffect(this.cells).concat(options.singleDirection ? orderedCells : orderedCells.reverse());

    var maxDelay = 0;
    orderedCells.forEach(function (ordered, index) {
        var delay;
        ordered.forEach(function (cell) {
            var hide = index >= orderedCells.length / 2;
            delay = index * (options.delay || 0.07);

            cleverapps.timeouts.setTimeout(function () {
                if (hide) {
                    this.cells[cell.y][cell.x].updateViewProperties();
                } else {
                    var fillColor = options.colors && options.colors[index] || cleverapps.Random.random(0, BlocksGame.COLORS.length - 1);
                    if (options.withExplode) {
                        this.cells[cell.y][cell.x].explode();
                    }
                    this.cells[cell.y][cell.x].updateViewProperties({ color: fillColor, mark: false });
                }
            }.bind(this), delay * 1000);
        }, this);
        maxDelay = Math.max(maxDelay, delay || 0);
    }, this);

    if (callback) {
        cleverapps.timeouts.setTimeout(callback, maxDelay * 1000);
    }
};

Grid.prototype.toStringArray = function () {
    return this.cells.map(function (colorsRow) {
        var rowStr = "";
        for (var w = 0; w < colorsRow.length; w++) {
            rowStr += colorsRow[w].isEmpty() ? "." : "x";
        }
        return rowStr;
    });
};

Grid.prototype.runClearBang = function () {
    new ActionPlayer([
        function (f) {
            Game.currentGame.counter.inc();
            f();
        },

        function (f) {
            this.trigger("runClearBang");
            cleverapps.timeouts.setTimeout(f, 1100);
        }.bind(this),

        function (f) {
            Game.currentGame.history.reset();
            this.fill(Grid.FILL_EFFECTS.ToCenterWithoutBorder, f, {
                delay: 0.1,
                singleDirection: true,
                colors: [3, 6, 0],
                withExplode: true
            });
        }.bind(this),

        function (f) {
            this.dropCells(f);
        }.bind(this),

        function (f) {
            Game.currentGame.counter.dec();
            f();
        }
    ]).play();
};

Grid.prototype.dropCellsAnimation = function () {
    var maxFallHeight = 0;

    for (var i = 0; i < Grid.WIDTH; i++) {
        var emptyCells = 0;
        for (var j = 0; j < Grid.HEIGHT; j++) {
            if (this.cells[j][i].isEmpty()) {
                emptyCells++;
            } else if (emptyCells > 0) {
                this.cells[j - emptyCells][i].setProperties(this.cells[j][i].getInfo());
                this.cells[j][i].resetProperties();
                maxFallHeight = Math.max(maxFallHeight, emptyCells);
                this.cells[j - emptyCells][i].trigger("onFall", this.cells[j][i].getView().getPosition(), emptyCells);
            }
        }
    }

    return maxFallHeight;
};

Grid.prototype.dropCells = function (callback) {
    var fallHeight = 0;
    
    new ActionPlayer([
        function (f) {
            fallHeight = this.dropCellsAnimation();
            cleverapps.timeouts.setTimeout(f, fallHeight * CellView.FALL_TIME_PER_CELL * 1000);
        }.bind(this),

        function (f) {
            if (fallHeight) {
                Game.currentGame.checkLines(cleverapps.Random.random(0, BlocksGame.COLORS.length - 1));
                cleverapps.timeouts.setTimeout(this.dropCells.bind(this, f), 500);
            } else {
                f();
            }
        }.bind(this)
    ]).play(callback);
};

Grid.FILL_EFFECTS = {
    LinearFromTop: function (cells) {
        return Grid.FILL_EFFECTS.LinearFromBottom(cells).reverse();
    },

    LinearFromBottom: function (cells) {
        var res = [];
        for (var i = 0; i < cells.length; i++) {
            res.push([]);
            for (var j = 0; j < cells[i].length; j++) {
                res[i].push({
                    x: j,
                    y: i
                });
            }
        }
        return res;
    },

    LinearFromLeft: function (cells) {
        var res = [];
        for (var i = 0; i < cells.length; i++) {
            var col = [];
            for (var j = 0; j < cells[i].length; j++) {
                col.push({
                    x: i,
                    y: j
                });
            }
            res.push(col);
        }
        return res;
    },

    FromCenter: function (cells) {
        var res = [];
        for (var i = 0; i < cells.length; i++) {
            for (var j = 0; j < cells[i].length; j++) {
                var cubeLevel = Math.max(Math.abs((cells.length - 1) - 2 * i), Math.abs((cells[i].length - 1) - 2 * j));
                while (!res[cubeLevel]) {
                    res.push([]);
                }
                res[cubeLevel].push({
                    x: j,
                    y: i
                });
            }
        }
        return res.reverse();
    },

    ToCenterWithoutBorder: function (cells) {
        var size = cells.length;
        var res = [];
        var centerX = Math.floor((size - 1) / 2);
        var centerY = Math.floor((size - 1) / 2);

        for (var i = 1; i < size - 1; i++) {
            for (var j = 1; j < size - 1; j++) {
                var cubeLevel = Math.max(Math.abs(centerX - i), Math.abs(centerY - j));
                while (!res[cubeLevel]) {
                    res.push([]);
                }
                res[cubeLevel].push({
                    x: j,
                    y: i
                });
            }
        }
        return res;
    },

    Spiraling: function (cells) {
        var res = [];
        var directions = [[0, 1], [1, 0], [0, -1], [-1, 0]];
        var toTravel = [cells[0].length, cells.length - 1];
        var direction = 0;
        var i = 0;
        var j = -1;

        while (toTravel[direction % 2] > 0) {
            for (var cellIndex = 0; cellIndex < toTravel[direction % 2]; cellIndex++) {
                i += directions[direction][0];
                j += directions[direction][1];
                res.push([{
                    x: j,
                    y: i
                }]);
            }
            toTravel[direction % 2]--;
            direction = (direction + 1) % 4;
        }
        return res;
    }
};

Grid.WIDTH = 8;
Grid.HEIGHT = 8;
Grid.EXPLODE_DURATION = 1500;
