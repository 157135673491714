/**
 * Created by Ivan on 23.05.2024
 */

var IceOnCellView = cc.Node.extend({
    ctor: function (iceOnCell) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this.setLocalZOrder(11);

        this.iceOnCell = iceOnCell;

        this.animation = new cleverapps.Spine(bundles.blocks.jsons.ice_json);
        this.animation.setAnimation(0, "idle" + this.getStage(), false);
        this.addChild(this.animation);
        this.setCascadeColorEnabled(true);

        iceOnCell.on("breakOff", this.createListener(this.breakOffAnimation.bind(this)));
        iceOnCell.on("remove", this.createListener(function () {
            this.stopAllActions();
            this.removeFromParent();
        }, this));
    },

    breakOffAnimation: function () {
        var stage = this.getStage();
        if (this.iceOnCell.stage === IceOnCell.PARTS) {
            this.animation.setAnimation(0, "animation" + stage, false);
        } else {
            this.animation.setAnimationAndIdleAfter("animation" + stage, "idle" + stage);
        }
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.2),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.blocks.urls.ice_stage_effect);
            })
        ));
    },

    getStage: function () {
        return Math.min(this.iceOnCell.stage, IceOnCell.PARTS);
    }
});
