/**
 * Created by Ivan on 13.11.2023
 */

cleverapps.overrideStyles(cleverapps.styles.RestoreProgressButton, {
    width: 200,
    height: 77
});

cleverapps.overrideStyles(cleverapps.styles.LoseWindow, {
    content: {
        margin: 10,

        padding: {
            bottom: 20,
            top: 10
        }
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    GAME_MESSAGE_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },
    COMBO_TEXT: {
        name: "combo_digits",
        size: 90
    }
});