/**
 * Created by olga on 05.03.2024
 */

var EditorGoalView = cleverapps.Layout.extend({
    ctor: function (goal) {
        var icon = GoalView.getGoalImage(goal.type);
        icon.setAnimation(0, "idle", false);
        var valueBlock = new IncDecBlock({
            value: goal.target,
            range: [goal.type === "score" ? -1 : 0, 100000],
            onChange: goal.setTarget.bind(goal)
        });

        this._super([icon, valueBlock], {
            direction: cleverapps.UI.HORIZONTAL
        });

        goal.on("update", this.createListener(function (target) {
            valueBlock._setValue(target, true);
        }));
    }

});