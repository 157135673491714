/**
 * Created by olga on 04.03.2024
 */

var EditorGoalsView = cleverapps.Layout.extend({
    ctor: function (goals) {
        var caption = cleverapps.UI.generateTTFText("Goals: ", cleverapps.styles.FONTS.EDITOR_BLUE_TEXT);
        var items = [caption];
        goals.getItems().forEach(function (goal) {
            items.push(new EditorGoalView(goal));
        });

        this._super(items, {
            direction: cleverapps.UI.VERTICAL
        });
    }
});
