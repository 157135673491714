/**
 * Created by Aleksandr on 03.05.2023
 */

var ExplodeHintsView = cc.Node.extend({
    ctor: function (hints, color) {
        this._super();

        var grid = Game.currentGame.grid;
        this.setContentSize2(grid.getView().getContentSize());

        hints.forEach(function (hint) {
            var spineName = hint.isVertical ? bundles.blocks.jsons.explode_hint_vertical_json : bundles.blocks.jsons.explode_hint_json;
            var explodeHint1 = new cleverapps.Spine(spineName);
            var explodeHint2 = new cleverapps.Spine(spineName);

            [explodeHint1, explodeHint2].forEach(function (hint) {
                hint.setAnchorPoint(0.5, 0.5);
                hint.setAnimation(0, "animation", true);
                hint.setColor(BlocksGame.COLORS[color].rgb);
                this.addChild(hint);
            }.bind(this));

            var shiftedByLinesPosition = { x: hint.x, y: hint.y };
            if (hint.width) {
                shiftedByLinesPosition.x += hint.width;
                if (shiftedByLinesPosition.x === grid.width) {
                    explodeHint1.setVisible(false);
                }
                if (hint.x === 0) {
                    explodeHint2.setVisible(false);
                }
            }
            if (hint.height) {
                shiftedByLinesPosition.y += hint.height;
                if (shiftedByLinesPosition.y === grid.height) {
                    explodeHint1.setVisible(false);
                }
                if (hint.y === 0) {
                    explodeHint2.setVisible(false);
                }
            }
            explodeHint1.setPositionRound(GridView.cellToPosition(shiftedByLinesPosition));
            explodeHint2.setPositionRound(GridView.cellToPosition(hint));
            explodeHint2.setRotation(180);
        }.bind(this));
    }
});