/**
 * Created by olga on 07.03.2024
 */

var ModeSelectorView = cleverapps.Layout.extend({
    ctor: function (modeSelector) {
        this.modeSelector = modeSelector;

        var modeLayout = this.createContentLayout({
            name: "mode",
            text: "Difficulty mode: ",
            value: modeSelector.value,
            range: [0, modeSelector.maxMode],
            updateRange: this.updateModeBlock.bind(this),
            onChange: modeSelector.setMode.bind(modeSelector),
            roundToRange: true
        });

        var maxModeLayout = this.createContentLayout({
            name: "maxMode",
            text: "Max difficulty mode: ",
            value: modeSelector.maxMode,
            range: [0, BlocksGame.MODES.length - 1],
            onChange: function (value) {
                modeSelector.setMaxMode(value);

                this.updateModeBlock();
            }.bind(this),
            roundToRange: true
        });

        this._super([modeLayout, maxModeLayout], {
            direction: cleverapps.UI.VERTICAL,
            margin: 15
        });
    },

    createContentLayout: function (options) {
        var contentCaption = cleverapps.UI.generateTTFText(options.text, cleverapps.styles.FONTS.EDITOR_BLUE_TEXT);
        var contentBlock = new IncDecBlock(options);

        this[options.name] = contentBlock;

        this.modeSelector.on("update", this.createListener(function (content) {
            if (content[options.name] !== undefined) {
                contentBlock._setValue(content[options.name], true);
            }

            if (options.updateRange) {
                options.updateRange();
            }
        }));

        return new cleverapps.Layout([contentCaption, contentBlock], {
            direction: cleverapps.UI.HORIZONTAL
        });
    },

    updateModeBlock: function () {
        this.mode.setRange([0, this.modeSelector.maxMode]);
        this.mode._setValue(this.mode.value);
    }

});