/**
 * Created by Ivan on 23.05.2024
 */

var IceOnCell = function (cell, options) {
    cleverapps.EventEmitter.call(this);
    options = options || {};

    this.cell = cell;
    this.stage = options.stage || 0;

    this.onBreakOff = function () {};
    this.onRemove = function () {};
};

IceOnCell.prototype = Object.create(cleverapps.EventEmitter.prototype);
IceOnCell.prototype.constructor = IceOnCell;

IceOnCell.prototype.getViewClass = function () {
    return IceOnCellView;
};

IceOnCell.prototype.getInfo = function () {
    return {
        name: "ice",
        stage: this.stage
    };
};

IceOnCell.prototype.explode = function () {
    this.stage++;

    this.trigger("breakOff");

    if (this.stage === IceOnCell.PARTS) {
        this.remove();
    }

    return true;
};

IceOnCell.prototype.remove = function () {
    this.cell.removeComponent(IceOnCell);
    Game.currentGame.goalCounter.setTimeout(function () {
        this.trigger("remove");
    }.bind(this), IceOnCell.BREAK_OFF_DURATION);
};

IceOnCell.PARTS = 2;
IceOnCell.BREAK_OFF_DURATION = 1000;