/**
 * Created by olga on 29.02.2024
 */

var CellView = cc.Sprite.extend({
    ctor: function (cell) {
        this.cell = cell;
        this._super(bundles.blocks.frames.cell_bg_red_png);
        this.setAnchorPoint(0, 0);
        this.update();
        this.setCascadeOpacityEnabled(true);

        cell.getView = function () {
            return this;
        }.bind(this);

        cell.on("update", this.createListener(this.update.bind(this)));
        cell.on("collectMark", this.createListener(this.collectMark.bind(this)));
        cell.on("remove", this.createListener(this.remove.bind(this)));
        cell.on("onFall", this.createListener(this.fall.bind(this)));
    },

    update: function (options) {
        options = options || {};
        var color = options.color !== undefined ? options.color : this.cell.getColor();

        if (this.markView) {
            this.markView.removeFromParent();
            this.markView = undefined;
        }

        (this.components || []).forEach(function (componentView) {
            componentView.removeFromParent();
        });

        var mark = options.mark !== undefined ? options.mark : this.cell.getMark();
        var components = options.components || this.cell.getComponents();

        this.stopAllActions();

        if (color === BlocksGame.COLOR_NONE) {
            this.setVisible(false);
        } else {
            this.setVisible(!options.fadeIn);

            this.setSpriteFrame(new cc.Sprite(BlocksGame.COLORS[color].image).getSpriteFrame());

            if (mark) {
                this.markView = new MarkView(mark);
                this.addChild(this.markView);
            }

            this.components = [];
            (components || []).forEach(function (component) {
                var ComponentViewClass = component.getViewClass();
                var view = new ComponentViewClass(component);
                view.setPositionRound(this.width / 2, this.height / 2);
                this.components.push(view);
                this.addChild(view);
            }.bind(this));

            this.setOpacity(this.cell.getHover() ? 82 : 255);

            if (options.fadeIn) {
                this.fadeIn();
            }
        }
    },

    runAnimation: function (spineName, color) {
        var animation = new cleverapps.Spine(spineName);

        if (color !== undefined) {
            animation.setColor(BlocksGame.COLORS[color].rgb);
        }

        animation.setAnchorPoint(0, 0);
        animation.setAnimation(0, "animation", false);
        this.addChild(animation);
        animation.replaceParentSamePlace(cleverapps.scenes.getRunningScene());
        animation.setCompleteListenerRemove();
    },

    remove: function (color) {
        this.runAnimation(bundles.blocks.jsons.cell_remove_json, color);
    },

    collectMark: function () {
        this.runAnimation(bundles.blocks.jsons.explode_goal_json);
    },

    fall: function (startPosition, fallHeight) {
        var lightAnimation = new cleverapps.Spine(bundles.blocks.jsons.cell_light_json);
        this.addChild(lightAnimation, 1000);
        lightAnimation.setPositionRound(this.width / 2, this.height / 2);

        var finishPosition = this.getPosition();
        this.setPosition(startPosition);
        this.runAction(new cc.Sequence(
            new cc.MoveTo(fallHeight * CellView.FALL_TIME_PER_CELL, finishPosition.x, finishPosition.y).easing(cc.easeIn(1)),
            new cc.CallFunc(function () {
                lightAnimation.setAnimation(0, "animation", false);
                lightAnimation.setCompleteListenerRemove();
                Game.currentGame.shakeField();
            })
        ));
    },

    fadeIn: function () {
        this.runAction(new cc.Sequence(
            new cc.FadeOut(0),
            new cc.Show(),
            new cc.FadeIn(0.3)
        ));
    }
});

CellView.getCellSize = function () {
    return { width: Cell.WIDTH * resolutionScale, height: Cell.HEIGHT * resolutionScale };
};

CellView.FALL_TIME_PER_CELL = 0.08;
