/**
 * Created by mac on 3/11/23
 */

var PieceView = cc.Node.extend({
    ctor: function (piece, isStatic) {
        this._super();
        this.piece = piece;
        this.cubeColor = piece.color;
        this.view = new cc.Node();

        this.cubes = piece.cells.map(this.createCube.bind(this));

        this.enable();
        this.setScale(0.5);

        cleverapps.UI.wrap(this.view);
        cleverapps.UI.wrapWithPadding(this.view, cleverapps.styles.PieceView.padding, true);
        this.view.setCascadeOpacityEnabledRecursively(true);

        if (!isStatic && !cleverapps.config.wysiwygMode) {
            this.addControls();
            piece.getView = this.createListener(function () {
                return this;
            }.bind(this));

            piece.onEnable = this.createListener(this.enable.bind(this));
            piece.onDisable = this.createListener(this.disable.bind(this));
        }

        this.setAnchorPoint(0.5, 0.5);
        this.addChild(this.view);
        this.setContentSize(this.view);
    },

    createCube: function (cell) {
        var mark = this.piece.getMark(cell.x, cell.y);

        var placeholder = new cc.Node();
        placeholder.setContentSize2(CellView.getCellSize());
        placeholder.setPositionRound(cell.x * placeholder.width, cell.y * placeholder.height);
        placeholder.setLocalZOrder(this.piece.form.length - cell.y);
        this.view.addChild(placeholder);
        var cube = new cc.Sprite();

        cube.image = mark ? mark.getImage() : BlocksGame.COLORS[this.cubeColor].image;

        cube.disableImage = mark ? mark.getDisableImage() : BlocksGame.DISABLE_COLOR.image;

        cube.setAnchorPoint(0, 0);
        cube.setPositionRound(0, 0);
        cube.orderIndex = cell.x + cell.y;
        placeholder.addChild(cube);
        return cube;
    },

    calculateViewPosition: function () {
        var globalBox = this.view.getBoundingBoxToWorld();
        return cc.p(globalBox.x + globalBox.width / 2, globalBox.y + globalBox.height / 2);
    },

    addControls: function () {
        this.pointHandler = cleverapps.UI.onDrag(this, {
            instantDrag: true,
            followPointer: this.createListener(function (touch) {
                this.setPositionRound(this.parent.convertTouchToNodeSpaceDisplacement(touch));
            }.bind(this)),

            onClick: this.createListener(this.piece.onClick.bind(this.piece)),
            onDragStart: this.createListener(this.piece.dragStart.bind(this.piece)),

            onDragMove: this.createListener(function () {
                this.piece.dragMove(this.calculateViewPosition());
            }.bind(this)),

            onDragEnd: this.createListener(function () {
                this.piece.dragEnd(this.calculateViewPosition());
            }.bind(this))
        });
    },

    stopDragAnimation: function () {
        this.stopAllActions();
    },

    needDragOffset: function () {
        return connector.info.isMobile;
    },

    animateStartDrag: function () {
        var styles = cleverapps.styles.PieceView.dragOffset;

        this.stopDragAnimation();
        cleverapps.audio.playSound(bundles.game.urls.drag_effect);
        this.getParent().setLocalZOrder(2);

        if (this.needDragOffset()) {
            this.view.runAction(new cc.MoveBy(0.1, styles.x, styles.y).easing(cc.easeExponentialOut(2)));
        }

        this.runAction(new cc.ScaleTo(0.1, 1).easing(cc.easeIn(2)));
    },

    animateEndDrag: function () {
        var styles = cleverapps.styles.PieceView.dragOffset;

        this.stopDragAnimation();
        this.getParent().setLocalZOrder(1);

        if (this.needDragOffset()) {
            this.view.runAction(new cc.MoveBy(0.1, styles.x, -styles.y));
        }

        this.runAction(
            new cc.Spawn(
                new cc.ScaleTo(0.1, 0.5),
                new cc.MoveTo(0.1, 0, 0)
            )
        );
    },

    enable: function () {
        this.cubes.forEach(function (cube) {
            cube.runAction(new cc.Sequence(
                new cc.DelayTime(cube.orderIndex * 0.05),
                new cc.CallFunc(function () {
                    cube.setSpriteFrame(cube.image);
                })
            ));
        });
    },

    disable: function () {
        this.cubes.forEach(function (cube) {
            cube.runAction(new cc.Sequence(
                new cc.DelayTime(cube.orderIndex * 0.05 + 0.3),
                new cc.CallFunc(function () {
                    cube.setSpriteFrame(cube.disableImage);
                })
            ));
        });
    },

    onRemove: function (silent) {
        if (this.pointHandler) {
            this.pointHandler.remove();
        }

        if (silent) {
            this.removeFromParent();
            return;
        }
        this.runAction(new cc.Sequence(
            new cc.ScaleTo(0.2, 0.55).easing(cc.easeCubicActionOut()),
            new cc.ScaleTo(0.3, 0).easing(cc.easeCubicActionIn()),
            new cc.RemoveSelf()
        ));
    }
});

cleverapps.styles.PieceView = {
    dragOffset: {
        x: 0,
        y: 160
    },

    padding: {
        x: 120,
        y: 120
    }
};