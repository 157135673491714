/**
 * Created by Aleksandr on 17.04.2023
 */

var PieceSlotView = cc.Node.extend({
    addPiece: function (piece, silent) {
        var pieceView = this.pieceView = new PieceView(piece);
        this.addChild(pieceView);

        if (!silent) {
            this.showUpPiece(pieceView);
        }
    },

    replacePiece: function (options) {
        var pieceView = this.pieceView = new PieceView(options);
        pieceView.setVisible(false);
        this.addChild(pieceView);

        pieceView.runAction(new cc.Sequence(
            new cc.DelayTime(0.5),
            new cc.Show(),
            new cc.CallFunc(this.showUpPiece.bind(this, pieceView))
        ));
    },

    showUpPiece: function (pieceView) {
        var scale = pieceView.getScale();
        pieceView.setScale(0);
        var appear = new cleverapps.Spine(bundles.blocks.jsons.appear_json);
        appear.setPosition(pieceView.getPosition());
        appear.setAnimation(0, "animation", false);
        appear.setCompleteListenerRemove();
        appear.setLocalZOrder(-1);
        this.addChild(appear, -1);

        pieceView.runAction(new cc.ScaleTo(0.25, scale));
    },

    removePiece: function (silent) {
        if (!this.pieceView) {
            return;
        }

        this.pieceView.onRemove(silent);
        delete this.pieceView;
    },

    takeAnimation: function () {
        var takeAnimation = new cleverapps.Spine(bundles.blocks.jsons.take_json);
        takeAnimation.setAnimation(0, "animation", false);
        takeAnimation.setCompleteListenerRemove();
        this.addChild(takeAnimation, -1);
    }

});