/**
 * Created by Andrey Popov on 3/24/23.
 */

var Pieces = function (game, savedPieces) {
    this.game = game;
    this.nextPieces = savedPieces || undefined;

    this.pid = 0;
    this.pieces = [undefined, undefined, undefined];

    this.onStartDrag = function () {};
    this.onNewPiece = function () {};
    this.onReplacePiece = function () {};
    this.onTookPiece = function () {};

    this.onShowHint = function () {};
    this.onHidePieces = function () {};
    this.onHide = function () {};
};

Pieces.prototype.onClick = function (index) {
    var orangery = this.game.orangery;
    if (orangery && orangery.selected) {
        var form;
        var color;
        if (orangery.selected.eraser) {
            form = -1;
        } else if (orangery.selected.form) {
            form = orangery.selected.form;
            color = orangery.selected.color;
            orangery.unselect(orangery.selected);
        } else {
            color = orangery.selected.color;
        }
        this.replacePiece({ index: index, form: form, color: color });
    }
};

Pieces.prototype.dragStartPiece = function (index) {
    var piece = this.pieces[index];
    if (piece.singleMoveHint) {
        this.game.grid.showHint(piece.form, piece.singleMoveHint);
    }
    this.onStartDrag(index);
};

Pieces.prototype.getPiece = function (index) {
    return this.pieces[index];
};

Pieces.prototype.isEmpty = function () {
    return this.pieces.filter(Boolean).length === 0;
};

Pieces.prototype.take = function (index, silent) {
    this.pieces[index] = undefined;

    this.onTookPiece(index, silent);
};

Pieces.prototype.getNewPieces = function (callback, silent) {
    if (this.nextPieces) {
        ++this.pid;

        this.pieces = this.nextPieces.map(function (piece, index) {
            if (piece) {
                piece = new Piece(index, piece);
                return piece;
            } 
            return undefined;
        });

        this.nextPieces = undefined;

        this.showNewPieces(silent);
        callback();
    } else if (!this.calcPieceFormsInProcess) {
        var pid = ++this.pid;

        this.calcPieceFormsInProcess = true;
        Game.currentGame.calcPieceForms(function (forms) {
            this.calcPieceFormsInProcess = false;

            if (this.pid === pid) {
                this.pieces = forms.map(function (form, index) {
                    var piece = new Piece(index, { form: form, color: this.calcPieceColor() });
                    piece.setRandomGoal();
                    return piece;
                }.bind(this));

                this.showNewPieces(silent);
            }

            callback();
        }.bind(this));
    }
};

Pieces.prototype.showNewPieces = function (silent) {
    this.onHidePieces(true);
    for (var i = 0; i < this.pieces.length; i++) {
        if (this.pieces[i]) {
            this.onNewPiece(this.pieces[i], silent);
        }
    }
};

Pieces.prototype.calcPieceColor = function () {
    this.lastColor = (this.lastColor === undefined) ? 0 : this.lastColor + 1;

    if (this.lastColor === BlocksGame.COLORS.length) {
        this.lastColor = 0;
    }

    return this.lastColor;
};

Pieces.prototype.getInfo = function () {
    return this.pieces.map(function (piece) {
        return piece ? piece.getInfo() : undefined;
    });
};

Pieces.prototype.startHint = function (target) {
    this.onShowHint(this.pieces[1], target);
};

Pieces.prototype.setHint = function (index, hint) {
    this.pieces[index] && this.pieces[index].setHint(hint);
};

Pieces.prototype.clearHint = function (index) {
    this.pieces[index] && this.pieces[index].clearHint();
};

Pieces.prototype.replacePiece = function (options) {
    this.onTookPiece(options.index, false);
    if (options) {
        this.pieces[options.index] = new Piece(options.index, options);
        this.onReplacePiece(this.pieces[options.index]);
    } else {
        this.pieces[options.index] = undefined;
    }

    Game.currentGame.setTimeout(function () {
        Game.currentGame.checkMoves();
    }, 500);
};