/**
 * Created by olga on 06.03.2024
 */
var EditorGrid = function () {
    Grid.apply(this, arguments);
};

EditorGrid.prototype = Object.create(Grid.prototype);
EditorGrid.constructor = EditorGrid;

EditorGrid.prototype.onClick = function (x, y) {
    var cell = this.getCell(x, y);
    var orangery = cleverapps.config.editorMode ? Editor.currentEditor.orangery : Game.currentGame.orangery;

    if (orangery && orangery.selected) {
        if (orangery.selected.form) {
            var color = orangery.selected.components || orangery.selected.mark ? cell.getColor() : undefined;
            if (!color || color === BlocksGame.COLOR_NONE) {
                color = orangery.selected.color;
            }

            var piece = new Piece(undefined, {
                form: orangery.selected.form,
                color: color,
                mark: orangery.selected.mark,
                components: orangery.selected.components,
                fromOrangery: true
            });
            Editor.currentEditor.history.add({
                redo: function () {
                    Game.currentGame.placePiece(piece, x, y);
                    Editor.currentEditor.save();
                },

                undo: function () {
                    Game.currentGame.removePiece(piece, x, y);
                    Editor.currentEditor.save();
                }
            });

            return;
        }

        if (orangery.selected.eraser) {
            if (!cell.isEmpty()) {
                Editor.currentEditor.history.add({
                    redo: function () {
                        cell.resetProperties();
                        Editor.currentEditor.save();
                    },

                    undo: Editor.currentEditor.restoreCell(cell)
                });
            }
            return;
        }

        if (orangery.selected.color !== undefined) {
            if (!cell.isEmpty()) {
                Editor.currentEditor.history.add({
                    redo: function () {
                        cell.setProperties({ color: orangery.selected.color });
                        Editor.currentEditor.save();
                    },

                    undo: Editor.currentEditor.restoreCell(cell)
                });
            }
            return;
        }
    }

    if (!cell.isEmpty()) {
        Editor.currentEditor.history.add({
            redo: function () {
                cell.explode(cell.color);
                Editor.currentEditor.save();
            },

            undo: Editor.currentEditor.restoreCell(cell)
        });
    }
};