/**
 * Created by mac on 3/11/23
 */

var PiecesView = cc.Node.extend({
    ctor: function (pieces) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        this.pieces = pieces;

        this.bg = cleverapps.UI.createScale9Sprite(bundles.game.frames.pieces_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        this.addChild(this.bg);

        this.slots = [];
        for (var i = 0; i < this.pieces.pieces.length; i++) {
            var slot = new PieceSlotView();
            this.addChild(slot);
            this.slots.push(slot);
        }
        this.resize();

        this.pieces.onNewPiece = this.createListener(this.addPiece.bind(this));
        this.pieces.onReplacePiece = this.createListener(this.replacePiece.bind(this));
        this.pieces.onTookPiece = this.createListener(this.removePiece.bind(this));
        this.pieces.onStartDrag = this.createListener(this.startDrag.bind(this));
        this.pieces.onShowHint = this.createListener(this.showHint.bind(this));
        this.pieces.onHidePieces = this.createListener(this.clearPieces.bind(this));
        this.pieces.onHide = this.createListener(this.hide.bind(this));
    },

    resize: function () {
        this.hideHint();
        var styles = cleverapps.styles.PiecesView;
        if (cleverapps.resolution.mode !== cleverapps.WideMode.VERTICAL) {
            this.setContentSize2(styles.item.width + styles.bgPadding * 2, styles.item.height * 3 + styles.bgPadding * 2);
        } else {
            this.setContentSize2(styles.item.width * 3 + styles.bgPadding * 2, styles.item.height + styles.bgPadding * 2);
        }
        this.bg.setContentSize2(this.width, this.height);
        this.bg.setPositionRound(this.width / 2, this.height / 2);
        this.setSlotPositions();
    },

    setSlotPositions: function () {
        var styles = cleverapps.styles.PiecesView;
        for (var i = 0; i < this.pieces.pieces.length; i++) {
            var slot = this.slots[i];
            var itemHeight = styles.item.height;
            var itemWidth = styles.item.width;
            if (cleverapps.resolution.mode !== cleverapps.WideMode.VERTICAL) {
                slot.setPositionRound(this.width / 2, i * itemHeight + itemHeight / 2 + styles.bgPadding);
            } else {
                slot.setPositionRound(i * itemWidth + itemWidth / 2 + styles.bgPadding, this.height / 2);
            }
        }
    },

    startDrag: function (index) {
        this.hideHint();
        this.slots[index].takeAnimation();
    },

    removePiece: function (index, silent) {
        this.slots[index].removePiece(silent);
    },

    clearPieces: function (silent) {
        this.slots.forEach(function (slot) {
            slot.removePiece(silent);
        });
    },

    hide: function (silent) {
        this.clearPieces(silent);

        if (this.bg) {
            this.bg.runAction(
                new cc.FadeOut(0.3)
            );
        }
    },

    addPiece: function (piece, silent) {
        this.slots[piece.index].addPiece(piece, silent);
    },

    replacePiece: function (piece) {
        this.slots[piece.index].replacePiece(piece);
    },

    showHint: function (piece, target) {
        this.hideHint();

        var path = [
            this.slots[1],
            target
        ];

        var gridView = Game.currentGame && Game.currentGame.grid && Game.currentGame.grid.getView();
        var fieldScale = cc.Base.GetHierarchyScale(gridView).x;

        var hintPiece = new PieceView(piece, true);
        hintPiece.setOpacity(150);
        hintPiece.setPositionRound(this.slots[1].getPosition());
        this.addChild(hintPiece);

        this.finger = FingerView.hintDrag(path, {
            payload: hintPiece,
            payloadScale: fieldScale
        });
    },

    hideHint: function () {
        FingerView.remove(this.finger);
        this.finger = undefined;
    }

});

cleverapps.styles.PiecesView = {
    item: {
        width: 259,
        height: 259
    },

    bgPadding: 20
};
