/**
 * Created by mac on 3/11/23
 */

var GridView = cc.Node.extend({
    ctor: function (grid) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        this.grid = grid;
        this.background = this.addBackground();
        this.setContentSize(this.background.width, this.background.height);
        this.background.setPositionRound(this.width / 2, this.height / 2);

        this.floor = this.addFloor();
        this.content = this.addContent();
        this.addModeFrame();

        this.grid.getView = this.createListener(function () {
            return this.content;
        }.bind(this));

        grid.on("explode", this.createListener(this.explode.bind(this)));
        grid.on("showCombo", this.createListener(this.showCombo.bind(this)));
        grid.on("hideGrid", this.createListener(this.hideGrid.bind(this)));
        grid.on("changeModeFrameColor", this.createListener(this.changeModeFrameColor.bind(this)));
        grid.on("showHint", this.createListener(this.showHint.bind(this)));
        grid.on("hideHint", this.createListener(this.hideHint.bind(this)));
        grid.on("explodeHint", this.createListener(this.showExplodeHint.bind(this)));
        grid.on("resetExplodeHint", this.createListener(this.hideExplodeHint.bind(this)));
        grid.on("runClearBang", this.createListener(this.runClearBang.bind(this)));

        if (cleverapps.config.debugMode && !cleverapps.config.wysiwygMode) {
            this.addDebugInfo();

            this.pointHandler = cleverapps.UI.onClick(this.content, function (touch) {
                var position = this.content.convertTouchToNodeSpace(touch);
                var coords = GridView.positionToCell(position);
                coords.x = Math.floor(coords.x);
                coords.y = Math.floor(coords.y);
                this.grid.onClick(coords.x, coords.y);
            }.bind(this), { interactiveScale: false });
        }

        this.debugId = "GridView";
    },

    addBackground: function () {
        var styles = cleverapps.styles.GridView;
        var bg = cleverapps.UI.createScale9Sprite(bundles.blocks.frames.grid_border_png, cleverapps.UI.Scale9Rect.TwoPixelXY);
        var cellSize = CellView.getCellSize();
        bg.setContentSize2(cellSize.width * Grid.WIDTH + styles.borderOffset * 2, cellSize.height * Grid.HEIGHT + styles.borderOffset * 2);
        this.addChild(bg);
        return bg;
    },

    addFloor: function () {
        var floor = new cc.Node();
        var cellSize = CellView.getCellSize();
        floor.setContentSize2(cellSize.width * Grid.WIDTH, cellSize.height * Grid.HEIGHT);
        floor.setAnchorPoint(0.5, 0.5);
        floor.setCascadeOpacityEnabled(true);
        this.grid.iterateCells(function (cell, x, y) {
            var placeholder = new cc.Sprite(bundles.blocks.frames.grid_cell_bg_png);
            if (cleverapps.config.debugMode) {
                if (placeholder.width !== cellSize.width || placeholder.height !== cellSize.height) {
                    throw "Wrong size of cell, should be " + JSON.stringify(cellSize);
                }
            }
            placeholder.setAnchorPoint(0, 0);
            placeholder.setPositionRound(x * cellSize.width, y * cellSize.height);
            floor.addChild(placeholder);
        });
        floor.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(floor);
        return floor;
    },

    addContent: function () {
        var styles = cleverapps.styles.GridView;
        var content = new cc.Node();
        var cellSize = CellView.getCellSize();
        content.setContentSize2(cellSize.width * Grid.WIDTH, cellSize.height * Grid.HEIGHT);
        content.setAnchorPoint(0.5, 0.5);
        this.grid.iterateCells(function (cell, x, y) {
            var cellView = new CellView(cell);
            cellView.setLocalZOrder(this.calcCellZOrder(x, y));
            cellView.setPositionRound(x * cellSize.width + styles.cellOffset.x, y * cellSize.height + styles.cellOffset.x);
            content.addChild(cellView);
        }.bind(this));
        content.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(content);
        return content;
    },

    addModeFrame: function () {
        var styles = cleverapps.styles.GridView;
        if (bundles.blocks.jsons.mode_frame) {
            var modeFrame = this.modeFrame = new cleverapps.Spine(bundles.blocks.jsons.mode_frame);
            modeFrame.setAnimation(0, "animation", true);
            modeFrame.setPositionRound(styles.modeFrame);
            modeFrame.setLocalZOrder(-1);
            modeFrame.setVisible(false);
            this.addChild(modeFrame);
        }
    },

    explode: function (rows, columns, color) {
        this.addLineExplodeAnimation(rows, color);
        this.addLineExplodeAnimation(columns, color, true);
        cleverapps.audio.playSound(bundles.blocks.urls.explode_line_effect);
    },

    addLineExplodeAnimation: function (lines, color, isVertical) {
        lines.forEach(function (line) {
            var animation = new cleverapps.Spine(bundles.blocks.jsons.line_explode_json);
            animation.setAnchorPoint(0.5, 0.5);
            animation.setAnimation(0, "animation", false);
            animation.setCompleteListenerRemove();
            animation.setColor(BlocksGame.COLORS[color].rgb);
            animation.setRotation(isVertical ? -90 : 0);

            var x = isVertical ? line + 0.5 : Grid.WIDTH / 2;
            var y = isVertical ? Grid.HEIGHT / 2 : line + 0.5;
            animation.setPosition(GridView.cellToPosition(cc.p(x, y)));
            this.floor.addChild(animation);
        }.bind(this));
    },

    showCombo: function (amount) {
        var styles = cleverapps.styles.GridView.combo;

        var comboAnimation = new cleverapps.Spine(bundles.blocks.jsons.explode_combo_json);
        this.addChild(comboAnimation);
        comboAnimation.setPositionRound(styles.animation);

        var text = cleverapps.UI.generateImageText("+" + amount.toString(), cleverapps.styles.FONTS.COMBO_TEXT);
        text.fitTo(styles.text.width, styles.text.height);
        text.setVisible(false);
        text.setScale(0.4);
        text.setCascadeOpacityEnabled(true);
        text.setOpacity(0);
        this.addChild(text);
        text.setPositionRound(styles.text);

        text.runAction(new cc.Sequence(
            new cc.DelayTime(0.65),
            new cc.Spawn(
                new cc.Show(),
                new cc.Sequence(
                    new cc.ScaleTo(0.2, 1.4).easing(cc.easeCubicActionOut()),
                    new cc.ScaleTo(0.3, 1).easing(cc.easeCubicActionIn())
                ),
                new cc.FadeIn(0.5)
            ),
            new cc.DelayTime(0.15),
            new cc.FadeOut(0.1),
            new cc.RemoveSelf()
        ));

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.5),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.game.urls.message_effect);
                comboAnimation.setAnimation(0, "animation", false);
                comboAnimation.setCompleteListenerRemove();
            })
        ));
    },

    hideGrid: function (callback) {
        this.floor.runAction(
            new cc.FadeOut(0.3)
        );

        this.background.runAction(
            new cc.Sequence(
                new cc.FadeOut(0.3),
                new cc.CallFunc(callback)
            )
        );

        if (this.modeCircle) {
            this.modeCircle.removeFromParent();
        }

        if (this.modeFrame) {
            this.modeFrame.removeFromParent();
        }
    },

    changeModeFrameColor: function (color) {
        if (this.modeFrame) {
            this.modeFrame.setVisible(true);
            this.modeFrame.setColor(color);
        }
    },

    addDebugInfo: function () {
        var styles = cleverapps.styles.GridView;

        var modeCircle = this.modeCircle = new cc.Sprite(bundles.finger.frames.tutorial_click_png);
        modeCircle.setPositionRound(styles.modeCircle);
        modeCircle.setScale(0.6);
        this.addChild(modeCircle);

        var estimationText = cleverapps.UI.generateOnlyText("", cleverapps.styles.FONTS.SCORE_TEXT);
        estimationText.setPositionRound(modeCircle.width / 2, modeCircle.height / 2);
        modeCircle.addChild(estimationText);

        var modeText = cleverapps.UI.generateOnlyText("", cleverapps.styles.FONTS.SMALL_WHITE_TEXT);
        modeText.setPositionRound(modeCircle.width / 2, -modeCircle.height / 2);
        modeCircle.addChild(modeText);

        this.grid.updatePiecesData = this.createListener(function (color, chosen) {
            modeCircle.setColor(color);
            estimationText.setString(chosen && chosen.estimation || "");
            modeText.setString("Mode: " + Game.currentGame.mode);

            if (this.debugHints) {
                this.debugHints.forEach(function (debugHint) {
                    debugHint.stopAllActions();
                    debugHint.removeFromParent();
                });
                delete this.debugHints;
            }

            if (cleverapps.gameModes.showHint) {
                if (chosen && chosen.positions) {
                    this.debugHints = [];
                    chosen.positions.forEach(function (pos, index) {
                        var hint = new HintPieceView(pos);
                        hint.setPositionRound(GridView.cellToPosition(cc.p(0, 0)));
                        hint.setLocalZOrder(GridView.HINT_ZORDER);
                        hint.show();
                        this.content.addChild(hint);

                        this.debugHints.push(hint);

                        hint.runAction(new cc.Sequence(
                            new cc.Hide(),
                            new cc.DelayTime(index * 2),
                            new cc.Show(),
                            new cc.DelayTime(1.5),
                            new cc.Hide(),
                            new cc.RemoveSelf(),
                            new cc.CallFunc(function () {
                                if (index === chosen.positions.length - 1) {
                                    delete this.debugHints;
                                }
                            }.bind(this))
                        ));
                    }.bind(this));
                }
            }
        });
    },

    showHint: function (form, cell) {
        var hint = this.hintView = new HintPieceView(form);
        hint.setPositionRound(GridView.cellToPosition(cell));
        hint.setLocalZOrder(2);
        this.content.addChild(hint);
        hint.show();
    },

    hideHint: function () {
        if (this.hintView) {
            this.hintView.hide();
            this.hintView = undefined;
        }
    },

    showExplodeHint: function (hints, color) {
        var explodeHints = this.explodeHints = new ExplodeHintsView(hints, color);
        explodeHints.setLocalZOrder(GridView.HINT_ZORDER);
        this.content.addChild(explodeHints);
    },

    hideExplodeHint: function () {
        if (this.explodeHints) {
            this.explodeHints.stopAllActions();
            this.explodeHints.removeFromParent();
            delete this.explodeHints;
        }
    },

    calcCellZOrder: function (x, y, highlight) {
        return x + (this.grid.height - y) + (highlight ? GridView.HINT_ZORDER : 3);
    },

    animateBomb: function () {
        var scene = cleverapps.scenes.getRunningScene();

        var bomb = new cleverapps.Spine(bundles.blocks.jsons.clear_bang_json);
        this.addChild(bomb);

        bomb.replaceParentSamePlace(cleverapps.scenes.getMovingNode(bomb));

        bomb.setPositionRound(bomb.parent.convertToNodeSpace(scene.clearPiecesButtonBooster.convertToWorldSpace(scene.clearPiecesButtonBooster.icon)));

        var targetPosition = bomb.parent.convertToNodeSpace(this.parent.convertToWorldSpace(this.getPosition()));

        bomb.setAnimation(0, "animation", false);
        bomb.setCompleteListenerRemove();
        bomb.runAction(new cc.MoveTo(1.1, targetPosition.x, targetPosition.y).easing(cc.easeOutIn(1)));
    },

    animateExplode: function () {
        var explode = new cleverapps.Spine(bundles.blocks.jsons.clear_bang_explode_json);
        this.addChild(explode);
        explode.setPositionRound(this.width / 2, this.height / 2);

        explode.runAction(new cc.Sequence(
            new cc.DelayTime(0.8),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.blocks.urls.booster_bomb_effect);
            }),
            new cc.DelayTime(0.3),
            new cc.CallFunc(function () {
                explode.setAnimation(0, "animation", false);
                explode.setCompleteListenerRemove();

                Game.currentGame.shakeField({
                    fullShake: true
                });
            })
        ));
    },

    runClearBang: function () {
        this.animateBomb();
        this.animateExplode();
    }
});

GridView.cellToPosition = function (piece) {
    var cellSize = CellView.getCellSize();
    return cc.p(piece.x * cellSize.width, piece.y * cellSize.height);
};

GridView.positionToCell = function (position) {
    var cellSize = CellView.getCellSize();
    return cc.p(position.x / cellSize.width, position.y / cellSize.height);
};

GridView.HINT_ZORDER = 20;

cleverapps.overrideColors(cleverapps.styles.COLORS, {
    BLOCK_COLOR_RED: new cc.Color(209, 50, 55, 255),
    BLOCK_COLOR_GREEN: new cc.Color(90, 218, 93, 255),
    BLOCK_COLOR_BLUE: new cc.Color(56, 89, 247, 255),
    BLOCK_COLOR_YELLOW: new cc.Color(235, 171, 0, 255),
    BLOCK_COLOR_PURPLE: new cc.Color(142, 71, 210, 255),
    BLOCK_COLOR_CYAN: new cc.Color(28, 169, 255, 255),
    BLOCK_COLOR_ORANGE: new cc.Color(233, 92, 0, 255),

    MODE_COLOR_GREEN: new cc.Color(24, 237, 22, 255),
    MODE_COLOR_BLUE: new cc.Color(0, 210, 255, 255),
    MODE_COLOR_YELLOW: new cc.Color(255, 194, 0, 255),
    MODE_COLOR_ORANGE: new cc.Color(255, 128, 14, 255),
    MODE_COLOR_RED: new cc.Color(255, 46, 77, 255)
});

cleverapps.overrideStyles(cleverapps.styles.GameScene, {
    exclamation: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 250 }
    }
});

cleverapps.styles.GridView = {
    borderOffset: 9,

    modeCircle: {
        x: { align: "center", dx: -500 },
        y: { align: "center", dy: -500 }
    },

    modeFrame: {
        x: { align: "center" },
        y: { align: "center", dy: 44 }
    },

    cellOffset: {
        x: 0,
        y: 0
    },

    combo: {
        animation: {
            x: { align: "center", dx: 100 },
            y: { align: "center", dy: 20 }
        },
        text: {
            width: 240,
            height: 90,
            x: { align: "center", dx: 200 },
            y: { align: "center", dy: -15 }
        }
    }
};