/**
 * Created by mac on 1/27/21
 */

var Highscore = function () {
    cleverapps.EventEmitter.call(this);

    this.load();
};

Highscore.prototype = Object.create(cleverapps.EventEmitter.prototype);
Highscore.prototype.constructor = Highscore;

Highscore.prototype.setScore = function (score) {
    if (this.highscore === score) {
        return;
    }

    this.highscore = score;

    this.trigger("update", score);
};

Highscore.prototype.getScore = function () {
    return this.highscore;
};

Highscore.prototype.showView = function () {
    this.trigger("show");
};

Highscore.prototype.hideView = function () {
    this.trigger("hide");
};

Highscore.prototype.gamePlayed = function (outcome, game) {
    cleverapps.playSession.set(cleverapps.EVENTS.COMPLETE_LEVEL_DAU, true);

    var score = game.score.getPoints();
    var newHighscore = score > this.highscore;
    if (newHighscore) {
        this.setScore(score);
    }

    var mode = (cleverapps.config.type === "klondike") ? Highscore.LOWER_BETTER : Highscore.HIGHER_BETTER;
    if (mode === Highscore.LOWER_BETTER) {
        this.bestmoves = this.bestmoves || game.moves;
        if (game.moves < this.bestmoves) {
            this.bestmoves = game.moves;
        }
    } else if (mode === Highscore.HIGHER_BETTER) {
        if (game.moves > this.bestmoves) {
            this.bestmoves = game.moves;
        }
    }

    this.save();

    if (outcome !== GameBase.OUTCOME_VICTORY) {
        return;
    }

    if (cleverapps.meta.getType() === Meta.SIMPLE) {
        game.rewards.metaStar = newHighscore ? 3 : 1;
    }
    game.rewards[cleverapps.config.soft ? GameBase.REWARD_SOFT : GameBase.REWARD_HARD] = newHighscore ? 50 : 10;
};

Highscore.HIGHER_BETTER = 0;
Highscore.LOWER_BETTER = 1;

Highscore.prototype.getInfo = function () {
    return {
        highscore: this.highscore,
        bestmoves: this.bestmoves
    };
};

Highscore.prototype.updateInfo = function (data) {
    data = data || {};
    this.highscore = data.highscore || 0;
    this.bestmoves = data.bestmoves || 0;
};

Highscore.prototype.load = function () {
    var data = cleverapps.dataLoader.load(DataLoader.TYPES.HIGHSCORE);
    this.updateInfo(data);
};

Highscore.prototype.save = function (fromServer) {
    cleverapps.dataLoader.save(DataLoader.TYPES.HIGHSCORE, this.getInfo());
    if (!fromServer) {
        cleverapps.synchronizer.addUpdateTask("metha");
    }
};

cleverapps.InitByFeature["highscore"] = function () {
    cleverapps.highscore = new Highscore();
};