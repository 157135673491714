/**
 * Created by olga on 07.03.2024
 */

var ModeSelector = function () {
    cleverapps.EventEmitter.call(this);
    this.mode = 0;
    this.maxMode = 4;
};

ModeSelector.prototype = Object.create(cleverapps.EventEmitter.prototype);
ModeSelector.prototype.constructor = ModeSelector;

ModeSelector.prototype.setMode = function (mode) {
    this.mode = mode;
    Editor.currentEditor.save();
};

ModeSelector.prototype.setMaxMode = function (maxMode) {
    this.maxMode = maxMode;
    Editor.currentEditor.save();
};

ModeSelector.prototype.update = function (levelContent) {
    if (levelContent.maxMode !== undefined) {
        this.setMaxMode(levelContent.maxMode);
    }

    if (levelContent.mode) {
        this.setMode(levelContent.mode);
    }

    this.trigger("update", levelContent);
};

ModeSelector.prototype.getInfo = function () {
    return {
        mode: this.mode,
        maxMode: this.maxMode
    };
};
