/**
 * Created by olga on 01.03.2024
 */

var GoalsView = cc.Scale9Sprite.extend({
    ctor: function (goals) {
        this._super(bundles.game.frames.pieces_bg);
        this.goals = goals;

        this.setVisible(false);
        this.setAnchorPoint(0.5, 0.5);
        this.setGoals();
        this.updateSize();
        this.setupChildren();
    },

    show: function (silent) {
        if (cleverapps.gameModes.hideGoals) {
            return;
        }

        if (!silent) {
            this.setOpacity(0);
            this.runAction(new cc.Sequence(
                new cc.Show(),
                new cc.FadeIn(0.5)
            ));
        } else {
            this.setVisible(true);
            this.views.forEach(function (view) {
                if (view.show) {
                    view.show(silent);
                }
            });
        }
    },

    hide: function (silent) {
        if (silent) {
            this.setVisible(false);
        } else {
            this.runAction(new cc.FadeOut(0.3));
        }

        this.views.forEach(function (view) {
            if (view.hide) {
                view.hide(silent);
            }
        });
    },

    setGoals: function () {
        this.views = [];
        Object.values(this.goals.elements).forEach(function (goal) {
            var view = new GoalView(goal);
            this.views.push(view);

            cleverapps.aims.registerTarget(goal.type, view);
        }.bind(this));
        this.goalsLayout = new cleverapps.Layout(this.views, { margin: cleverapps.styles.GoalsView.margin });
        this.addChild(this.goalsLayout);
    },

    updateSize: function () {
        var styles = cleverapps.styles.GoalsView;
        this.setContentSize2(styles.sizes[cleverapps.resolution.mode]);

        this.goalsLayout.setOptions({
            direction: cleverapps.resolution.mode !== cleverapps.WideMode.HORIZONTAL ? cleverapps.UI.HORIZONTAL : cleverapps.UI.VERTICAL,
            margin: cleverapps.resolution.mode !== cleverapps.WideMode.HORIZONTAL ? styles.margin : styles.verticalMargin
        });
    },

    setupChildren: function () {
        this.setPositionRound(cleverapps.styles.GoalsView);
        this.goalsLayout.setPositionRound(this.width / 2, this.height / 2);
    }

});

cleverapps.styles.GoalsView = {
    sizes: [
        {
            width: 640,
            height: 140
        },
        {
            width: 640,
            height: 140
        },
        {
            width: 200,
            height: 600
        }
    ],
    x: { align: "center" },
    y: { align: "center" },
    margin: 60,
    verticalMargin: 85
};