/**
 * Created by olga on 22.02.2024
 */

var Piece = function (index, options) {
    options = options || {};

    this.index = index;
    this.form = options.form;
    this.color = options.color;

    this.cells = Forms.convertFormToCoordinates(this.getForm());

    if (options.mark) {
        this.mark = new Mark(options.mark.type);
        this.mark.coordinateInPiece = cc.p(options.mark.x || this.cells[0].x, options.mark.y || this.cells[0].y);
    }

    this.components = (options.components || []).map(function (component) {
        if (!component.position) {
            component.position = this.cells[0];
        }
        return component;
    }.bind(this));

    this.onEnable = function () {};
    this.onDisable = function () {};
    this.getView = function () {};
};

Piece.prototype.setRandomGoal = function () {
    var goals = Game.currentGame && Game.currentGame.goals;
    if (!goals) {
        return;
    }

    if (Math.random() < 0.5) {
        return;
    }

    var marks = [];
    for (var markName in BlocksGame.MARKS) {
        var mark = BlocksGame.MARKS[markName];

        var target = goals.findTargetFor(mark.target);
        if (!target || target.isCompleted()) {
            continue;
        }

        var remaining = target.getRemaining();
        var goalsOnField = Game.currentGame.grid.getCellsWithGoal(mark.goal).length;

        if ((remaining + 1) - goalsOnField > 0) {
            marks.push(markName);
        }
    }

    if (!marks.length) {
        return;
    }

    var randomCoords = cleverapps.Random.mathChoose(this.cells);
    this.mark = new Mark(cleverapps.Random.mathChoose(marks));
    this.mark.coordinateInPiece = randomCoords;
};

Piece.prototype.getInfo = function () {
    var info = {
        form: this.form,
        color: this.color,
        index: this.index
    };
    if (this.mark) {
        info.mark = {
            type: this.mark.getType(),
            x: this.mark.coordinateInPiece.x,
            y: this.mark.coordinateInPiece.y
        };
    }

    return info;
};

Piece.prototype.getForm = function () {
    return this.form;
};

Piece.prototype.getColor = function () {
    return this.color;
};

Piece.prototype.getMark = function (x, y) {
    if (this.mark && this.mark.coordinateInPiece.x === x && this.mark.coordinateInPiece.y === y) {
        return this.mark;
    }
};

Piece.prototype.getComponents = function (x, y) {
    return this.components.filter(function (component) {
        return cc.pointEqualToPoint(component.position, cc.p(x, y));
    });
};

Piece.prototype.getCenter = function () {
    return {
        x: this.form[0].length / 2,
        y: this.form.length / 2
    };
};

Piece.prototype.isLocked = function () {
    return !this.enabled;
};

Piece.prototype.enable = function () {
    this.enabled = true;
    this.onEnable();
};

Piece.prototype.disable = function () {
    if (cleverapps.gameModes.piecesAlwaysEnabled) {
        return;
    }
    this.enabled = false;
    this.onDisable();
};

Piece.prototype.setHint = function (hint) {
    if (cleverapps.gameModes.hideSingleMoveHint) {
        return;
    }

    this.singleMoveHint = hint;
};

Piece.prototype.clearHint = function () {
    this.singleMoveHint = undefined;
};

Piece.prototype.onClick = function () {
    Game.currentGame.pieces.onClick(this.index);
};

Piece.prototype.dragStart = function () {
    if (this.dragging || this.isLocked() || Game.currentGame.counter.isActive() || Game.currentGame.outcome !== GameBase.OUTCOME_UNKNOWN) {
        return false;
    }
    this.dragging = true;
    this.getView().animateStartDrag();
    Game.currentGame.pieces.dragStartPiece(this.index);
    return true;
};

Piece.prototype.dragMove = function (pos) {
    Game.currentGame.dragMovePiece(this, pos);
};

Piece.prototype.dragEnd = function (pos) {
    this.dragging = false;
    this.getView().animateEndDrag();
    Game.currentGame.dragEndPiece(this, pos);
};
