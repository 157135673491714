/**
 * Created by spepa on 15.03.2023
 */

var ScoreStatsComponent = cc.Node.extend({
    ctor: function () {
        this._super();

        this.setAnchorPoint(0.5, 0.5);
        this.createContent();
    },

    simpleEntry: function (name, value) {
        return cleverapps.UI.generateOnlyText(Messages.get(name) + ": " + value, cleverapps.styles.FONTS.WHITE_TEXT);
    },

    createContent: function () {
        var styles = cleverapps.styles.ScoreStatsComponent;

        var views;
        var items = [];
        items.push(this.simpleEntry("moves", Game.currentGame.moves));

        if (cleverapps.highscore.bestmoves) {
            items.push(this.simpleEntry("bestmoves", cleverapps.highscore.bestmoves));
        }

        views = new cleverapps.Layout(items, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding
        });

        if (styles.background) {
            var background = new cc.Scale9Sprite(bundles.game.frames.loop_stats_bg);
            background.setPositionRound(views.width / 2, views.height / 2);
            background.setContentSize2(views.getContentSize());
            this.addChild(background);
        }

        views.setPositionRound(views.width / 2, views.height / 2);
        this.addChild(views);

        this.setContentSize2(views.getContentSize());
    },

    beforeWinClose: function () {
        this.setCascadeOpacityEnabledRecursively(true);
        this.runAction(new cc.FadeOut(0.1));
    }
});

cleverapps.styles.ScoreStatsComponent = {
    margin: 20,
    padding: {
        x: 50,
        y: 20
    },
    background: true
};
