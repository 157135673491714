/**
 * Created by olga on 11.03.2024
 */

var Mark = function (type) {
    cleverapps.EventEmitter.call(this);

    this.type = type;
};

Mark.prototype = Object.create(cleverapps.EventEmitter.prototype);
Mark.prototype.constructor = Mark;

Mark.prototype.collect = function () {
    var target = this.getTargetName();
    var goal = Game.currentGame.goals.findTargetFor(target);

    var callback;

    if (goal) {
        goal.inc(1, true);
        Game.currentGame.goalCounter.setTimeout(function () {}, Mark.COLLECT_DURATION);
        callback = function () {
            goal.onCollect(1);
        };
    } else if (this.type === "letter") {
        Game.currentGame.addClover(Mission.TYPE_LETTER, 1, true);
        callback = function () {
            Game.currentGame.trigger("rewardClover");
        };
    }

    this.trigger("collect", target, callback);
};

Mark.prototype.getType = function () {
    return this.type;
};

Mark.prototype.getImage = function () {
    return BlocksGame.MARKS[this.type].image;
};

Mark.prototype.getSpine = function () {
    return BlocksGame.MARKS[this.type].spine;
};

Mark.prototype.getDisableImage = function () {
    return BlocksGame.MARKS[this.type].disableImage;
};

Mark.prototype.getTargetName = function () {
    return BlocksGame.MARKS[this.type].target || BlocksGame.MARKS[this.type].getTarget();
};

Mark.COLLECT_DURATION = 1200;