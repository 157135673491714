/**
 * Created by Aleksandr on 17.04.2023
 */

var HintPieceView = cc.Node.extend({
    ctor: function (form) {
        this._super();
        var cells = Forms.convertFormToCoordinates(form);
        cells.forEach(function (cell) {
            var placeholder = new cc.Node();
            placeholder.setContentSize2(CellView.getCellSize());
            placeholder.setPositionRound(cell.x * placeholder.width, cell.y * placeholder.height);
            placeholder.setCascadeOpacityEnabled(true);
            this.addChild(placeholder);

            var bg = new cc.Sprite(bundles.blocks.frames.hint_bg_png);
            bg.setPosition(placeholder.width / 2, placeholder.height / 2);
            placeholder.addChild(bg);
        }.bind(this));

        this.setCascadeOpacityEnabled(true);
        this.setOpacity(0);
    },

    show: function () {
        this.runAction(new cc.FadeIn(0.25));
    },

    hide: function () {
        this.stopAllActions();
        this.runAction(new cc.Sequence(
            new cc.FadeOut(),
            new cc.RemoveSelf()
        ));
    }
});