/**
 * Created by olga on 29.02.2024
 */

var Cell = function (options) {
    cleverapps.EventEmitter.call(this);
    this.x = options.x;
    this.y = options.y;
    options.color = options.color !== undefined ? options.color : BlocksGame.COLOR_NONE;

    this.setProperties(options);
};

Cell.prototype = Object.create(cleverapps.EventEmitter.prototype);
Cell.prototype.constructor = Cell;

Cell.prototype.getInfo = function () {
    if (this.isEmpty()) {
        return;
    }
    var info = {
        color: this.color,
        x: this.x,
        y: this.y
    };
    if (this.mark !== undefined) {
        info.mark = this.mark.getType();
    }
    info.components = this.components.map(function (component) {
        return component.getInfo();
    });
    return info;
};

Cell.prototype.setProperties = function (options) {
    if (options.color !== undefined) {
        this.color = options.color;
    }

    var mark = options.mark;
    if (mark !== undefined) {
        this.mark = new Mark(mark);
    }

    this.components = (options.components || []).map(function (component) {
        switch (component.name) {
            case "ice": return new IceOnCell(this, component);
        }
        return undefined;
    }.bind(this));

    this.updateViewProperties({
        fadeIn: options.fadeIn
    });
};

Cell.prototype.resetProperties = function (silent) {
    this.color = BlocksGame.COLOR_NONE;
    this.mark = undefined;

    this.components.forEach(function (component) {
        component.remove();
    });

    this.components = [];

    if (!silent) {
        this.updateViewProperties();
    }
};

Cell.prototype.updateViewProperties = function (options) {
    this.trigger("update", options);
};

Cell.prototype.isEmpty = function () {
    return this.color === BlocksGame.COLOR_NONE;
};

Cell.prototype.getColor = function () {
    return this.color;
};

Cell.prototype.getMark = function () {
    return this.mark;
};

Cell.prototype.getComponents = function () {
    return this.components;
};

Cell.prototype.getHover = function () {
    return this.isHovered;
};

Cell.prototype.setHover = function (options) {
    this.isHovered = true;
    this.updateViewProperties(options);
};

Cell.prototype.resetHover = function () {
    this.isHovered = false;
    this.updateViewProperties();
};

Cell.prototype.collectMark = function () {
    if (this.mark === undefined) {
        return;
    }
    this.mark.collect();
    this.mark = undefined;

    this.trigger("collectMark");
};

Cell.prototype.remove = function (color) {
    this.resetProperties();
    this.trigger("remove", color);
};

Cell.prototype.explode = function () {
    for (var i = 0; i < this.components.length; i++) {
        if (this.components[i].explode()) {
            return;
        }
    }

    this.collectMark();
    this.resetProperties();
};

Cell.prototype.isBlocked = function () {
    return this.components.filter(function (component) {
        return component.isBlocked();
    }).length;
};

Cell.prototype.removeComponent = function (Component) {
    for (var i = 0; i < this.components.length; i++) {
        if (this.components[i] instanceof Component) {
            this.components.splice(i, 1);
            break;
        }
    }
};

Cell.prototype.onFall = function (cell) {
    this.trigger("onFall", cell.getView());
};

Cell.WIDTH = 100;
Cell.HEIGHT = 100;
