/**
 * Created by Andrey Popov on 30.03.2023
 */

var HighScoreView = cc.Scale9Sprite.extend({
    ctor: function (highscore) {
        this._super(bundles.game.frames.high_score_bg);

        var styles = cleverapps.styles.UI.HighScoreView;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize(styles.bg);

        this.scoreView = cleverapps.UI.generateImageText(highscore.getScore(), cleverapps.styles.FONTS.HIGH_SCORE_TEXT);
        this.scoreView.setPositionRound(styles.text);
        this.addChild(this.scoreView);

        this.icon = new cc.Sprite(bundles.game.frames.high_score_icon);
        this.icon.setPositionRound(styles.icon);
        this.addChild(this.icon);

        this.setCascadeOpacityEnabledRecursively(true);
        this.setVisible(false);

        highscore.on("show", this.createListener(this.showScore.bind(this)), this);
        highscore.on("hide", this.createListener(this.hideScore.bind(this)), this);
        highscore.on("update", this.createListener(this.updateScore.bind(this)), this);
    },

    updateScore: function (points) {
        this.scoreView.countToAnimation(0.4, points);
        this.scoreView.fitTo(cleverapps.styles.UI.ScoreView.maxWidth);
    },

    showScore: function () {
        this.setOpacity(0);

        this.runAction(new cc.Sequence(
            new cc.Show(),
            new cc.FadeIn(0.5)
        ));
    },

    hideScore: function () {
        this.runAction(new cc.Sequence(
            new cc.FadeOut(0.5),
            new cc.Hide()
        ));
    }
});

cleverapps.overrideColors(cleverapps.styles.COLORS, {
    HIGHSCORE_COLOR: new cc.Color(241, 187, 24, 255)
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    HIGH_SCORE_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.HIGHSCORE_COLOR
    }
});

cleverapps.styles.UI.HighScoreView = {
    bg: {
        width: 250,
        height: 84
    },

    text: {
        x: { align: "center", dx: 44 },
        y: { align: "center" }
    },

    icon: {
        x: { align: "left", dx: 10 },
        y: { align: "center" }
    },

    maxWidth: 130
    
};